import React from 'react';
import { useRouter } from 'next/router';

import Button from 'legacy/common/components/button/Button';

const NotFoundPage = ({ message }) => {
  const router = useRouter();

  return (
    <div className="w-full m-auto">
      <div className="py-36 text-center">
        <h1 className="text-9xl text-gray-600">404</h1>
        <p className="text-xl text-gray-500">{message}</p>
        <div className="mt-14 lg:w-72 m-auto">
          <Button
            onClick={() => {
              router.push('/dashboard');
            }}
          >
            Back to Dashboard
          </Button>
        </div>
      </div>
    </div>
  );
};

NotFoundPage.defaultProps = {
  message: 'Oops! Sorry, we could not find the page.'
};

export default NotFoundPage;
